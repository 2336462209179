import {Routes} from '@angular/router';
import {LOGIN_REDIRECT, NOT_FOUND_REDIRECT} from '@common/globals/routes';

export const APP_ROUTES: Routes = [
  ...LOGIN_REDIRECT,
  {
    path: 'login',
    loadChildren: () => import('../main-pages/login-check/login-check.routes'),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('../main-pages/dashboard/dashboard.routes'),
  },
  {
    path: 'collections',
    loadChildren: () => import('../main-pages/collections/collections.routes'),
  },
  ...NOT_FOUND_REDIRECT,
];
