import {Injectable} from '@angular/core';
import {getSettingsSubOptions} from '@common/globals/ecommerceSettings';
import {CollectionUrls} from '@common/ts/collectionInterfaces';
import {MenuOverlaySubOptions} from '@common/ts/interfaces';
import {UserDataCommon} from '@common/ts/userDataCommon';

@Injectable()
export class UserDataService extends UserDataCommon<CollectionUrls> {

  getSettingsSubOptions(): MenuOverlaySubOptions<any>[] {
    const {country, enabledFeatureToggles} = this.userData;
    return getSettingsSubOptions(country, enabledFeatureToggles.inventoryImportEnabled);
  }

  dataRemover(): void {
    this.mainDataRemover();
  }

}
