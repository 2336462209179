import {Injectable} from '@angular/core';
import {MenuRoutesService} from '@clock/services/menu-routes-service/menu-routes.service';
import {UserDataService} from '@clock/services/user-data/user-data.service';
import {LocalStorageModuleTypes} from '@common/enum/localStorageModuleTypes';
import {ModuleTypes} from '@common/enum/moduleTypes.enum';
import {Roles} from '@common/enum/roles.enum';
import {EnvUrl} from '@common/statics/getEnvURL';
import {getLoginData} from '@common/statics/getLoginData';
import {AppModules} from '@common/statics/modules';
import {UserRoles} from '@common/statics/userRoles';
import {AuthCommon} from '@common/ts/authCommon';
import {CollectionUrls} from '@common/ts/collectionInterfaces';
import {Observable, of, switchMap} from 'rxjs';

@Injectable()
export class AuthService extends AuthCommon<CollectionUrls, Roles> {

  constructor(public routerSrv: MenuRoutesService,
              public override userDataService: UserDataService) {
    super(userDataService);
  }

  override authHandler(roles: Roles[]): Observable<boolean> {
    if (AppModules.hasModule(ModuleTypes.Collection)) {
      if (UserRoles.hasRole(roles)) {
        return this.userDataService.userDataCheck().pipe(switchMap((checked) => {
          if (checked) {
            if (!this.userDataService.userData.enabledFeatureToggles.collectionEnabled) {
              this.goToLogin();
            }
            this.userDataService.httpUpdate.next(false);
            if (!getLoginData().isImpersonate) {
              return this.userDataService.localSSrv.saveLocalStorageData(LocalStorageModuleTypes.Collection).pipe(
                switchMap(() => of(true)));
            }
            return of(true);
          }
          return of(false);
        }));
      }
      return this.goToLogin();
    } else if (AppModules.hasModule(ModuleTypes.TradeIn)) {
      window.location.assign(EnvUrl.getEnvURL('TradeIn'));
      return of(false);
    }
    return this.goToLogin();
  }

  goToLogin(): Observable<boolean> {
    window.location.assign(EnvUrl.getEnvURL('Ecommerce'));
    return of(false);
  }

  removeData(): void {
    this.userDataService.dataRemover();
    window.location.assign(`${EnvUrl.getEnvURL('Ecommerce')}/login`);
  }

}
