import {Injectable} from '@angular/core';
import {ADMIN_ROLES} from '@common/globals/adminRoles';
import {UserRoles} from '@common/statics/userRoles';
import {CollectionUrls} from '@common/ts/collectionInterfaces';
import {RouteCommon} from '@common/ts/routeCommon';

@Injectable()
export class MenuRoutesService extends RouteCommon<CollectionUrls> {

  dashboardRoute(): void {
    this.menuRoutes(UserRoles.hasRole(ADMIN_ROLES) ? 'collections' : 'dashboard');
  }

}
